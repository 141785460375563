  import React, { useState, useEffect  } from 'react';
  import Slider from 'react-slick';
  import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';
  import Clients from './Clients/Clients'
  import './Home.css';
  import './Solucoes.css'
  import './About.css'
  import { RiShareForwardFill } from "react-icons/ri";
  import HomeHeader from "../../layout/headerHome/HomeHeader";
  import Footer from "../../layout/footer/Footer";
  import Button from '../../components/Button';
  import EntreEmContato from '../../components/EntreEmContato';
  import SobreImge from '../../utils/img/sobre.jpeg';
  import Solucoes from './Solucoes';

 

  const Home = () => {


    const  mainSettings = {
      dots: false, // Remover os pontos de navegação
      arrows: true, // Adicionar setas de navegação apenas se não for mobile
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <IoIosArrowBack />,
      nextArrow: <IoIosArrowForward/>,
      autoplay: true, 
      autoplaySpeed: 8000, 
    };

    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        setIsLoaded(true); // Disparar a animação quando o componente monta
    }, []);

    return (
      <div>
        <HomeHeader />
        <main>
          <Slider {...mainSettings}>
            <div>
              <section className='containers'>
                <div className='background-blur'></div>
                <section className='container-main fade-in-up'>
                  <h1 className='title-principal'>Soluções Inovadoras para o Crescimento da sua Empresa</h1>
                  <p>Bem-vindo à Raitec Soluções em Tecnologia. Com expertise na área de TI, desenvolvimento de sites, business intelligence e soluções personalizadas, potencializamos o sucesso digital da sua empresa, oferecendo suporte, segurança e otimização da infraestrutura.</p>

                  <Button path="/servicos" description="Saiba Mais" />
                </section>
              </section>
            </div>
            <div>
              <section className='containers'>
                <div className='background-blur-contato'></div>
                <section className='container-main'>
                  <h1 className='title'>Potencialize o Sucesso do seu Negócio</h1>
                  <p>Com Experiência em TI e Desenvolvimento de Soluções Personalizadas. Suporte, Segurança e Otimização da Infraestrutura para sua Empresa. Consultoria Personalizada para Alcançar seus Objetivos de Negócio.</p>
                  <Button path="/contato" description="Solicitar Orçamento" />
                </section>
              </section>
            </div>
            <div>
              <section className='containers'>
                <div className='background-blur'></div>
                <section className='container-main fade-in-up'>
                  <h1 className='title-principal'>Soluções Inovadoras para o Crescimento da sua Empresa</h1>
                  <p>Bem-vindo à Raitec Soluções em Tecnologia. Com expertise na área de TI, desenvolvimento de sites, business intelligence e soluções personalizadas, potencializamos o sucesso digital da sua empresa, oferecendo suporte, segurança e otimização da infraestrutura.</p>

                  <Button path="/servicos" description="Saiba Mais" />
                </section>
              </section>
            </div>
            <div>
              <section className='containers'>
                <div className='background-blur-contato'></div>
                <section className='container-main'>
                  <h1 className='title'>Potencialize o Sucesso do seu Negócio</h1>
                  <p>Com Experiência em TI e Desenvolvimento de Soluções Personalizadas. Suporte, Segurança e Otimização da Infraestrutura para sua Empresa. Consultoria Personalizada para Alcançar seus Objetivos de Negócio.</p>
                  <Button path="/contato" description="Solicitar Orçamento" />
                </section>
              </section>
            </div>
       
          </Slider>

          <div className="slider-divider"></div>

          <section id='about'>
            <div className="container-about">

              <div className='about-container'>
                <div className='about-img-wrapper'>
                  <img src={SobreImge} alt='Handshake' className='about-img'/>
                  <ul className='services-list'>
                    <li className='service-item'><RiShareForwardFill className='v-middle'/>  Conheça Nossos Valores</li>
                    <li className='service-item'><RiShareForwardFill className='v-middle'/>  Nossa Missão pelo Mundo</li>
                    <li className='service-item'><RiShareForwardFill className='v-middle'/>  Resultados Comprovados</li>
                  </ul>
                </div>
                <div className='about-content'>
                  <h2 className='about-title'>Raitec Soluções em Tecnologia: </h2>
                  <h1>Acreditamos no poder da tecnologia para impulsionar os negócios.</h1>
                  <p>Com mais de <span>7 anos de experiência</span> na área de <span>TI</span>, desenvolvimento de <span>sites</span>, <span>business intelligence</span> e <span>soluções personalizadas</span>, ajudamos empresas de todos os portes a alcançarem seus <span>objetivos</span>. Nossa equipe de <span>profissionais experientes</span> e apaixonados por <span>tecnologia</span> está sempre buscando as melhores <span>soluções</span> para atender às suas necessidades específicas. Oferecemos um atendimento <span>personalizado</span> e de alta qualidade, com foco em <span>resultados concretos</span> para o seu negócio.</p>

                  <Button className="button-sobre" path="/sobre-nos" description="Saiba Mais Sobre Nós" />
                </div>
              </div>

            </div>
            
          </section>


          <Solucoes/>



          <Clients/>

          <EntreEmContato />

          <Footer />
        </main>
        

      </div>
    )
  }

  export default Home;
