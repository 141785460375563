
import React from 'react';
import Include from '../../../components/servicos-card/Include';
import Header from "../../../layout/header/Header";
import Footer from "../../../layout/footer/Footer";
import HeaderPages from "../../../components/HeaderPages";
import ImgBg from '../../../utils/img/Microsoft/bg.jpeg';  
import EntreEmContato from '../../../components/EntreEmContato';
import MicrosoftIcon from '../../../utils/img/icones/microsoft-select.png'
import Descricao from '../../../components/servicos-card/Descricao';
import '../Template.css';
import './Microsoft.css'
import MicrosoftImg from '../../../utils/img/Microsoft/microsoft.jpeg';

import Beneficios from '../../../components/servicos-card/Beneficios';
import Expertise from '../../../utils/img/Microsoft/expertise.png';
import Suporte from '../../../utils/img/Microsoft/suporte.png';
import Personalizado from '../../../utils/img/Microsoft/personalizado.png';

import { SiMicrosoftazure } from "react-icons/si";
import { SiDynamics365 } from "react-icons/si";
import { FaMicrosoft } from "react-icons/fa";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { FaFileSignature } from "react-icons/fa";
import { MdManageSearch } from "react-icons/md";

const Microsoft = () => {
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`, 
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Microsoft" icon={MicrosoftIcon} iconAjust="diminuir" description="Conheça nossa Parceira"path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
      
        <Descricao img={MicrosoftImg} pergunta=' Raitec Soluções em Tecnologia:' tituloAssunto='Seu Parceiro Microsoft de Confiança'assunto='A <span>Raitec Soluções em Tecnologia</span> é um Parceiro <span>Microsoft Silver</span> com expertise em soluções em <span>nuvem</span> e <span>licenciamento</span> para empresas de todos os portes. Oferecemos uma gama completa de serviços para ajudar você a aproveitar ao máximo os recursos da <span>Microsoft</span>, impulsionando a <span>produtividade</span>, a <span>segurança</span> e a <span>competitividade</span> do seu negócio.'></Descricao>
        
        <section id='service-description'>
          <h2>Nossos serviços em <span>Nuvem</span> incluem:</h2>
          <ul>
            <Include icon={<FaMicrosoft />} titulo='Microsoft 365: ' texto='Forneça à sua equipe as <span>ferramentas</span> de <span>produtividade</span> e <span>colaboração</span> mais recentes, como <span>Office 365</span>, <span>Exchange Online</span>, <span>SharePoint Online</span> e <span>Teams</span>.'></Include>
            <Include icon={<SiMicrosoftazure />} titulo='Azure: ' texto='Acesse uma plataforma de nuvem segura e escalável para hospedar seus aplicativos, dados e infraestrutura com o <span>Microsoft Azure</span>. Com o Azure, você obtém uma infraestrutura de <span>nuvem confiável</span> e <span>flexível</span> que oferece <span>segurança</span> de nível empresarial, conformidade com regulamentações globais e escalabilidade sob demanda para <span>atender</span> às <span>necessidades</span> do seu negócio.'></Include>
            <Include icon={<SiDynamics365 />} titulo='Dynamics 365: ' texto='Gerencie seus relacionamentos com <span>clientes</span>, <span>finanças</span>, <span>operações</span> e <span>cadeia de suprimentos</span> com um conjunto de aplicativos de negócios integrados.' ></Include>
         
          </ul>
        </section>


        <section id='service-description2'>
          <div className='container-licenciamneto'>
            <h2>Nossos serviços em <span>Licenciamento</span> incluem:</h2>
            <ul>
            
                <Include icon={<FaSortAmountUpAlt />} titulo='Licenciamento por volume: ' texto='<span>Obtenha descontos</span> em licenças da <span>Microsoft</span> ao comprar em <span>grandes quantidades</span>, garantindo uma <span>economia significativa</span> em sua estratégia de licenciamento.'></Include>
                <Include icon={<FaFileSignature />} titulo='Licenciamento baseado em assinatura: ' texto='Pague por software da <span>Microsoft</span> como um serviço, garantindo <span>previsibilidade orçamentária</span> e <span>flexibilidade</span>. Tendo as versões dos produtos mais <span>atualizadas</span>, além de poder ajustar facilmente o número de assinaturas conforme as necessidades da sua empresa evoluem.'></Include>
                <Include icon={<MdManageSearch />} titulo='Consultoria de licenciamento: ' texto='<span>Consultoria de licenciamento</span> para ajudá-lo a escolher as <span>licenças certas</span> para suas necessidades específicas e otimizar seus custos de software. Tendo certeza de que está obtendo as <span>licenças mais adequadas</span> para suas necessidades, evitando gastos desnecessários e garantindo uma infraestrutura de TI <span>eficiente</span> e <span>econômica</span>.'></Include>
    
                
            </ul>
          </div>
        </section>

        <section id='beneficios'>
          <h2>
          Com a <span>Raitec</span>, você terá:
          </h2>
          <h4 className='pc-beneficios'>
            Passe o cursor do mouse em cima dos cards para saber mais
          </h4>
          <h4 className='mobile-beneficios'>
            Clique em cima dos cards para saber mais
          </h4>
          <div className='container-beneficios'>
            <Beneficios titulo='Expertise em soluções Microsoft' texto='Nossa equipe de especialistas certificados pela Microsoft está pronta para ajudá-lo a escolher as <span>soluções certas</span> para suas necessidades.' bgImage={Expertise}/>
            
            <Beneficios titulo='Atendimento personalizado' texto='Oferecemos <span>atendimento personalizado</span> para garantir que você obtenha o máximo valor de seus investimentos em Microsoft.' bgImage={Personalizado}/>

            <Beneficios titulo='Suporte técnico premiado' texto='Nossa equipe de <span>suporte técnico</span> está disponível para ajudá-lo com qualquer problema que você possa ter.' bgImage={Suporte}/>

            


          </div>
        </section>

        <EntreEmContato/>
        <Footer></Footer> 

      </main>
    </div>
  );
};
export default Microsoft
