import React from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/home/Home'
import Contato from './pages/contato/Contato'
import Clientes from './pages/clientes/Clientes'
import Servicos from './pages/servicos/Servicos'
import Bi from './pages/servicos/bi/Bi'
import Infra from './pages/servicos/infra/Infra'
import SupTi from './pages/servicos/sup-ti/SupTi'
import Sites from './pages/servicos/dev-site/Site'
import Sobrenos from './pages/SobreNos/SobreNos'
import Privacidade from './pages/privacidade/Privacidade'
import Microsoft from './pages/servicos/Microsoft/Microsoft'


const AppRoutes = () => { 
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/contato' element={<Contato/>}></Route>
        <Route path='/clientes' element={<Clientes/>}></Route>
        <Route path='/servicos' element={<Servicos/>}></Route>
        <Route path='/servico/bi' element={<Bi/>}></Route>
        <Route path='/servico/infra' element={<Infra/>}></Route>
        <Route path='/servico/sites' element={<Sites/>}></Route>
        <Route path='/servico/sup-ti' element={<SupTi/>}></Route>
        <Route path='/sobre-nos' element={<Sobrenos/>}></Route>
        <Route path='/privacidade' element={<Privacidade/>}></Route>
        <Route path='/servico/microsoft' element={<Microsoft/>}></Route>
      </Routes>
    </Router>
  )
}

export default AppRoutes
