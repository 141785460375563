import React from 'react';
import { FaArrowRight } from "react-icons/fa"; // Certifique-se de que é 'fa', não 'fa6' a menos que seja específico de uma versão
import './ButtonSolutions.css'; // Certifique-se de que o nome do arquivo CSS está correto

const ButtonSolutions = ({ path, buttonName }) => {
  return (
    <div className='buttonSolution'>
        <button>
            <a href={path}>{buttonName} <span className='bg-arrow-services'><FaArrowRight className='arrow-btn-services'/></span></a>
        </button>
    </div>
  );
}

export default ButtonSolutions;
