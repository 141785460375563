
import React from 'react';
import Include from '../../../components/servicos-card/Include';
import Header from "../../../layout/header/Header";
import Footer from "../../../layout/footer/Footer";
import HeaderPages from "../../../components/HeaderPages";
import SupIcon from '../../../utils/img/icones/sup-ti-select.png';
import ImgBg from '../../../utils/img/SUPORTETI/sup-ti-img.jpeg';  
import EntreEmContato from '../../../components/EntreEmContato';
import Descricao from '../../../components/servicos-card/Descricao';
import '../Template.css';
import Manutencao from '../../../utils/img/SUPORTETI/manutencao.jpeg';
import { HiWrenchScrewdriver } from "react-icons/hi2";
import { TbClock24 } from "react-icons/tb";
import { FaGears } from "react-icons/fa6";
import { BsShieldLockFill } from "react-icons/bs";
import { LuDatabaseBackup } from "react-icons/lu";
import { SiIcloud } from "react-icons/si";
import { FaRegLightbulb } from "react-icons/fa6";
import Beneficios from '../../../components/servicos-card/Beneficios';
import { MdOutlineAttachMoney } from "react-icons/md";
import Custos from '../../../utils/img/SUPORTETI/reducao.png';
import Produtividade from '../../../utils/img/SUPORTETI/produtividade.png';
import Seguranca from '../../../utils/img/SUPORTETI/seguranca.png';
import Paz from '../../../utils/img/SUPORTETI/paz.png';
import Flexibilidade from '../../../utils/img/SUPORTETI/flexibilidade.png';


const SupTi = () => {
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,  // Usar template string aqui
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center calc(90% - 10px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Suporte TI" icon={SupIcon} iconAjust="ajust" description="Conheça nossa Solução" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
      
        <Descricao titulo='<span>Tranquilidade</span> e <span>Segurança</span> Absoluta para Sua <span>Empresa<span/>' gatilho='Cansado de lidar com problemas de TI que atrapalham o seu negócio?' resposta='A Raitec Soluções em Tecnologia <span>oferece um serviço</span> de Suporte TI Completo que garante o <span>funcionamento impecável</span> da sua infraestrutura, a <span>segurança dos seus dados</span> e a <span>produtividade da sua equipe</span>. Somos <span>especialistas em TI</span> e estamos aqui para te ajudar a alcançar seus objetivos com <span>total tranquilidade</span>.' img={Manutencao} pergunta='O que é Suporte Técnico?' tituloAssunto='É a Garantia do Funcionamento da Sua Empresa'assunto='O <span>suporte</span> de <span>TI</span> é um serviço essencial para empresas que buscam garantir o funcionamento contínuo e eficiente de suas operações tecnológicas. Com a crescente dependência da <span>tecnologia</span> em todos os setores, problemas de <span>TI</span> podem ter um impacto negativo significativo nos negócios, levando a interrupções operacionais, perda de dados e riscos de <span>segurança</span>. A <span>Raitec Soluções em Tecnologia</span> oferece uma solução abrangente de <span>Suporte TI Completo</span>, proporcionando uma abordagem proativa e personalizada que cobre todos os aspectos da infraestrutura de <span>TI</span> de uma empresa.'></Descricao>
    

        <section id='service-description'>
          <h2>Nossos serviços em <span>Suporte TI</span> incluem:</h2>
          <ul>
            <Include icon={<TbClock24 />} titulo='Monitoramento Proativo 24/7:' texto='Nossa equipe especializada monitora sua infraestrutura de TI <span>24 horas por dia, 7 dias por semana</span>, identificando e <span>solucionando problemas</span> antes que causem impacto no seu negócio.'></Include>
            <Include icon={<HiWrenchScrewdriver />} titulo='Suporte Técnico Remoto e Presencial: ' texto='Oferecemos <span>suporte técnico rápido e eficiente</span>, tanto <span>remotamente</span> quanto <span>presencialmente</span>, para atender às suas necessidades de forma personalizada.'></Include>
            <Include icon={<FaGears />} titulo='Instalação, Configuração e Manutenção: ' texto='Instalamos, configuramos e mantemos softwares e hardwares, <span>garantindo</span> que sua <span>infraestrutura</span> esteja sempre <span>atualizada e funcionando perfeitamente</span>.'></Include>
            <Include icon={<BsShieldLockFill />} titulo='Atualizações de Segurança Regulares: ' texto='Realizamos <span>atualizações de segurança</span> regulares para <span>proteger sua empresa</span> contra as últimas ameaças cibernéticas, garantindo a confidencialidade dos seus dados.'></Include>
            <Include icon={<LuDatabaseBackup />} titulo='Gerenciamento de Backups e Recuperação de Desastres: ' texto='Implementamos e gerenciamos <span>backups completos</span> da sua infraestrutura, <span>garantindo a recuperação rápida e segura</span> em caso de falhas ou desastres.'></Include>
            <Include icon={<SiIcloud />} titulo='Suporte em Cloud Computing: ' texto='Auxiliamos na <span>implementação, migração e gerenciamento</span> de soluções em cloud computing, <span>otimizando seus custos</span> e escalabilidade.'></Include>
            <Include icon={<FaRegLightbulb />} titulo='Consultoria em TI Estratégica: ' texto='Oferecemos consultoria especializada para <span>auxiliar</span> na definição da sua <span>estratégia de TI</span>, alinhando-a com os objetivos do seu negócio.'></Include>
            
          </ul>
        </section>

        <section id='beneficios'>
          <h2>
          Com a <span>Raitec</span>, você terá:
          </h2>
          <h4 className='pc-beneficios'>
            Passe o cursor do mouse em cima dos cards para saber mais
          </h4>
          <h4 className='mobile-beneficios'>
            Clique em cima dos cards para saber mais
          </h4>
          <div className='container-beneficios'>
            <Beneficios titulo='Redução de custos com TI' texto='<span>Evite gastos</span> inesperados com reparos, manutenções e tempo de inatividade.' bgImage={Custos}/>
            <Beneficios titulo='Aumento da produtividade' texto='Equipe livre de problemas técnicos, focada em suas atividades e <span>gerando mais resultados</span>.' bgImage={Produtividade}/>
            <Beneficios titulo='Maior segurança da informação' texto='<span>Proteção</span> completa <span>contra ataques</span> cibernéticos, perda de dados e sabotagens virtuais.' bgImage={Seguranca}/>
            <Beneficios titulo='Tranquilidade e paz de espírito' texto='Tenha a certeza de que sua infraestrutura de TI está em boas mãos, permitindo que você se concentre no que realmente importa: <span>o crescimento do seu negócio</span>.' bgImage={Paz}/>
            <Beneficios titulo='Escalabilidade e flexibilidade' texto='Infraestrutura preparada para <span>acompanhar o crescimento do seu negócio</span>, sem necessidade de investimentos adicionais em curto prazo.' bgImage={Flexibilidade}/>

          </div>
        </section>

        <EntreEmContato/>
        <Footer></Footer> 

      </main>
      
    </div>
  );
};

export default SupTi
