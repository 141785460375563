
import React from 'react';
import Header from "../../../layout/header/Header";
import Footer from "../../../layout/footer/Footer";
import '../Template.css';
import HeaderPages from "../../../components/HeaderPages";
import SitesIcon from '../../../utils/img/icones/sites-select.png';
import ImgBg from '../../../utils/img/SITES/programacao.jpeg';  
import EntreEmContato from '../../../components/EntreEmContato';
import Descricao from '../../../components/servicos-card/Descricao';
import SitesImg from '../../../utils/img/SITES/site.jpeg';
import Include from '../../../components/servicos-card/Include';
import { IoIosRocket } from "react-icons/io";
import { BiSupport } from "react-icons/bi";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { GrTechnology } from "react-icons/gr";
import { IoConstructSharp } from "react-icons/io5";
import { FaHourglassHalf } from "react-icons/fa6";
import Leads from '../../../utils/img/SITES/leads.png';
import Credibilidade from '../../../utils/img/SITES/credibilidade.jpg';
import Experience from '../../../utils/img/SITES/experience.png';
import Seguranca from '../../../utils/img/SITES/seguranca.png';
import Beneficios from '../../../components/servicos-card/Beneficios';


const Site = () => {
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,  
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center calc(100% - 160px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Web Sites" icon={SitesIcon} description="Conheça nossa Solução" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
      
        <Descricao titulo='Sua <span>Presença</span> <span>Digital</span> de <span>Sucesso</span>' gatilho='Problemas com leads e com a centralização das informações da sua empresa?' resposta='A <span>Raitec Soluções em Tecnologia</span> oferece <span>soluções completas</span> para o <span>desenvolvimento de sites</span>, desde a <span>criação de um layout moderno e responsivo</span> até a <span>implementação de funcionalidades complexas</span> e a <span>otimização para mecanismos de busca (SEO)</span>.' img={SitesImg} pergunta='Como um site profissional pode impactar o seu negócio?' tituloAssunto='Impulsionando e trazendo mais Clientes'assunto='Na <span>era digital</span>, ter um <span>site profissional</span> é fundamental para o <span>sucesso de qualquer negócio</span>. Seja para <span>vender produtos online</span>, <span>divulgar seus serviços</span> ou simplesmente <span>construir uma marca forte</span>, um <span>site bem desenvolvido</span> é a sua <span>vitrine para o mundo</span>.'></Descricao>
    
        <section id='service-description'>
          <h2>Nossos serviços em <span>Sites</span> incluem:</h2>
          <ul>
            <Include icon={<IoConstructSharp />} titulo='Sites personalizados: ' texto='Criamos <span>sites sob medida</span> para atender às suas <span>necessidades específicas</span> e aos <span>objetivos do seu negócio</span>. Garantindo que o <span>desempenho</span> e a <span>segurança</span> sejam priorizados. '></Include>
            <Include icon={<HiMiniComputerDesktop />} titulo='Design responsivo: ' texto='Seu <span>site</span> será <span>compatível</span> com todos os <span>dispositivos</span>, desde <span>computadores desktop</span> até <span>smartphones</span> e <span>tablets</span>. Oferecendo uma <span>experiência consistente</span> e <span>envolvente</span> em todos os dispositivos.'></Include>
            <Include icon={<GrTechnology />} titulo='Funcionalidades avançadas: ' texto='Implementamos <span>funcionalidades</span> como <span>e-commerce</span>, <span>blogs</span>, <span>portais de clientes</span> e <span>muito mais</span>. Nossa equipe de <span>desenvolvedores</span> cria <span>funcionalidades avançadas</span> para atender às <span>necessidades exclusivas</span> do seu negócio.' ></Include>
            <Include icon={<IoIosRocket />} titulo='Otimização para SEO: ' texto='Seu <span>site</span> terá maior <span>visibilidade nos resultados de pesquisa</span>, atraindo mais <span>visitantes qualificados</span>. Nossa equipe de <span>especialistas</span> em <span>SEO</span> aplica <span>técnicas avançadas</span> para <span>melhorar o posicionamento</span> do seu site nos <span>mecanismos de busca</span>. '></Include>
            <Include icon={<FaHourglassHalf />} titulo='Desenvolvimento ágil: ' texto='Entregamos seu <span>site</span> no <span>prazo</span> e dentro do <span>orçamento combinado</span>, garantindo que ele esteja <span>totalmente funcional</span> e <span>alinhado com seus objetivos de negócio</span>. '></Include>
            <Include icon={<BiSupport />} titulo='Suporte técnico especializado: ' texto='Nossa <span>equipe</span> estará sempre à disposição para te <span>auxiliar em qualquer problema técnico</span>, fornecendo <span>suporte ágil e eficiente</span> para garantir que suas operações continuem funcionando perfeitamente.'></Include>
  
            
          </ul>
        </section>

        <section id='beneficios'>
          <h2>
          Com a <span>Raitec</span>, você terá:
          </h2>
          <h4 className='pc-beneficios'>
            Passe o cursor do mouse em cima dos cards para saber mais
          </h4>
          <h4 className='mobile-beneficios'>
            Clique em cima dos cards para saber mais
          </h4>
          <div className='container-beneficios'>
            <Beneficios titulo='Mais Leads' texto='Ao implementar <span>técnicas de SEO</span> e <span>funcionalidades avançadas</span>, seu <span>site</span> terá maior <span>visibilidade nos motores de busca</span> e capacidade de <span>atrair visitantes qualificados</span>, convertendo-os em <span>leads</span> e <span>potenciais clientes</span>.' bgImage={Leads}/>
            
            <Beneficios titulo='Segurança para suas informações' texto='Seu <span>site</span> oferecerá uma <span>plataforma centralizada</span> para reunir <span>informações sobre sua empresa</span>, <span>produtos</span> e <span>serviços</span>, mantendo esses <span>dados seguros</span> e <span>acessíveis</span>, o que facilita a <span>tomada de decisões</span> e a <span>comunicação com os clientes</span>.' bgImage={Seguranca}/>

            <Beneficios titulo='Experiência de Usuário Aprimorada' texto='Com um <span>design responsivo</span> e <span>funcional</span>, seu <span>site</span> proporcionará uma <span>navegação intuitiva</span> e <span>fluida</span> em todos os <span>dispositivos</span>, melhorando a <span>experiência dos usuários</span> e incentivando-os a <span>explorar o conteúdo</span> de maneira mais <span>aprofundada</span>.' bgImage={Experience}/>

            <Beneficios titulo='Maior Credibilidade' texto='Um <span>site profissional</span> e <span>bem projetado</span> transmite <span>confiança</span> e <span>profissionalismo</span> aos <span>visitantes</span>, fortalecendo a <span>reputação da sua marca</span> e destacando-a no <span>mercado</span>.' bgImage={Credibilidade}/>


          </div>
        </section>

        <EntreEmContato/>

        <Footer></Footer> 
      </main>
      
    </div>
  );
};

export default Site
