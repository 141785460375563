import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import './CookiesSytle.css';

const CookiesBanner = () => {
    const [isCookieSet, setCookie] = useState(Boolean(localStorage.getItem("cookieConsent")));
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consentCookie = Boolean(localStorage.getItem("cookieConsent"));
        setCookie(consentCookie);

        // Adiciona a classe 'aparecer' após 3 segundos
        const timeout = setTimeout(() => {
            if(!isCookieSet){
                setShowBanner(true);
            }
            
        }, 3000);

        // Limpa o timeout quando o componente é desmontado
        return () => clearTimeout(timeout);
    }, []);

    const Aceito = () => {
        Cookies.set("cookieConsent", true);
        localStorage.setItem("cookieConsent", true);
        setCookie(true);
        setShowBanner(false); // Oculta o banner após aceitar os cookies
    };

    return (
        <div className={`cookies-msg ${showBanner ? 'aparecer' : ''} ${isCookieSet ? 'desaparecer' : ''}`} id='cookies-msg'>
            <div className='cookies-txt'>
                <h2>PARA A SUA MELHOR EXPERIÊNCIA</h2>
                <div className='content'>
                    <p>
                        O site da RAITEC utiliza cookies para melhorar sua experiência. Ao continuar navegando, você concorda com seu uso. Saiba mais em nossa política de  <a href="/privacidade">Privacidade</a>.
                    </p>
                    <div className='btns'>
                        <div className='cookies-btn'>
                            <button id='acc' onClick={Aceito}>Aceito</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesBanner;
