import React from 'react';
import './Beneficios.css';

const Beneficios = (props) => {
    const frontStyle = {
        backgroundImage: `url(${props.bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0, /* Estende o fundo para cobrir toda a área da div 'frente'. */
        zIndex: -1,
        borderRadius: '20px 20px 30px 30px',

    };

    return (
        <div className='espaco-card'>
            <div className='card-beneficios'>
                <div className='frente'>
                    <div style={frontStyle}></div>
                    <h3 className='titulo-beneficios'>{props.titulo}</h3>
                </div>
                <div className='tras'>
                    <p className='texto-beneficios' dangerouslySetInnerHTML={{ __html: props.texto }}></p>
                </div>
            </div>
        </div>
    );
}

export default Beneficios;
