import './App.css';
import AppRoutes from './AppRoutes';
import Cookies from './layout/cookies/Cookies';


const App = () => {
    return (
        <div>
            <Cookies/>
            <AppRoutes/>
            
        </div>
    );
};

export default App;