import React from 'react';

const Button = (props) => {
  const isWhatsAppLink = props.path === 'https://api.whatsapp.com/send?phone=5541987371760';

  return (
    <button className='buttonServices'>
      <a
        className="link-btn-services"
        href={props.path}
        target={isWhatsAppLink ? '_blank' : undefined}
        rel={isWhatsAppLink ? 'noopener noreferrer' : undefined}
      >
        {props.description}
      </a>
    </button>
  );
}

export default Button;
