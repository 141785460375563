// HeaderPages.jsx
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from "react-icons/fa6";
import './HeaderPages.css';

const HeaderPages = ({ imgBgStyle, title, icon, description, path, buttonName, iconAjust , titleAjust}) => {
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
      // Isso garante que a animação só ocorra quando o componente for montado
      setIsLoaded(true);
  }, []);

  
  
  return (
    <div>
        <section className='header-container'>
            <section className='container-header'>
                <h1 className={`${titleAjust} ${isLoaded ? 'fade-in-up' : ''}`} >
                    {title} <span className='bg-icon'><img src={icon} className={`icon-header ${iconAjust}`} /></span>
                </h1>
                <p className={`${isLoaded ? 'fade-in-up' : ''}`}>{description}</p>
                <button className={`${isLoaded ? 'fade-in-up' : ''}`}>
                    <a href={path} target="_blank" rel="noopener noreferrer">{buttonName} <span className='bg-arrow'><FaArrowRight className='arrow-btn'/></span></a>
                </button>
                <div style={imgBgStyle}></div>
            </section>
        </section>
    </div>
  );
};

export default HeaderPages;
