import React from 'react'
import './EntreEmContato.css'
import Mobile from '../../src/utils/img/contacting-mobile.png';
import { BsCheckCircleFill } from "react-icons/bs";

const EntreEmContato = () => {
  return (
    <div>
   <section className="contact-section">
      <div className='img'>
        <div className="contact-image">
          <img src={Mobile} alt="" className='mobile'/>
        </div>
      </div>
      
      <div className="contact-content">
        <h2>Entre em <span>contato</span></h2>
        <p>Estamos aqui para proporcionar uma <span>experiência</span> diferenciada.  <span> Se você está aqui</span>, é porque reconhece a oportunidade de transformar seu negócio em algo ainda melhor. Confira como podemos te ajudar:</p>
        <ul>
          <li> <BsCheckCircleFill className='v-middle check'/> Informações sobre nossos serviços.</li>
          <li> <BsCheckCircleFill className='v-middle check'/> Agendar uma avaliação gratuita.</li>
          <li> <BsCheckCircleFill className='v-middle check'/> Compreender suas necessidades específicas de tecnologia.</li>
        </ul>
        <p>Esperamos trabalhar com você em breve!</p>
        <a href="/contato"><button>Fale Conosco</button></a>
      </div>
    </section>
    <div className='space'>

    </div>
    </div>
  )
}

export default EntreEmContato
