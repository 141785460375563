import React, { useState, useEffect } from 'react';
import ButtonServices from '../../components/ButtonSolutions';
import './Solucoes.css'
import DashBoard from '../../utils/img/icones/dashboard.png';
import Infra from '../../utils/img/icones/infra.png';
import SupTi from '../../utils/img/icones/sup-ti.png';
import Sites from '../../utils/img/icones/sites.png';
import Microsoft from '../../utils/img/icones/microsoft.png';
import DashBoardSelect from '../../utils/img/icones/dashboard-select.png';
import InfraSelect from '../../utils/img/icones/infra-select.png';
import SupTiSelect from '../../utils/img/icones/sup-ti-select.png';
import SitesSelect from '../../utils/img/icones/sites-select.png';
import MicrosoftSelect from '../../utils/img/icones/microsoft-select.png';

const Solucoes = () => {
  const [serviceImages, setServiceImages] = useState({
    dashboard: DashBoard,
    infra: Infra,
    supTi: SupTi,
    sites: Sites,
    microsoft: Microsoft
  });

  const isLargeScreen = () => window.innerWidth > 770;

  useEffect(() => {
    // Update the images based on screen size when component mounts
    updateImages();
    // Listen for resize events to update images accordingly
    window.addEventListener('resize', updateImages);
    // Cleanup function to remove event listener when component unmounts
    return () => window.removeEventListener('resize', updateImages);
  }, []);

  const updateImages = () => {
    const newImages = isLargeScreen()
      ? { 
          dashboard: DashBoard,
          infra: Infra,
          supTi: SupTi,
          sites: Sites,
          microsoft: Microsoft
        }
      : {
          dashboard: DashBoardSelect,
          infra: InfraSelect,
          supTi: SupTiSelect,
          sites: SitesSelect,
          microsoft: MicrosoftSelect
        };
    setServiceImages(newImages);
  };

  const handleMouseEnter = (service) => {
    if (isLargeScreen()) {
      setServiceImages((prevState) => {
        switch (service) {
          case 'dashboard':
            return { ...prevState, dashboard: DashBoardSelect };
          case 'infra':
            return { ...prevState, infra: InfraSelect };
          case 'supTi':
            return { ...prevState, supTi: SupTiSelect };
          case 'sites':
            return { ...prevState, sites: SitesSelect };
          case 'microsoft':
            return { ...prevState, microsoft: MicrosoftSelect };
          default:
            return prevState;
        }
      });
    }
  };

  const handleMouseLeave = (service) => {
    if (isLargeScreen()) {
      setServiceImages((prevState) => {
        switch (service) {
          case 'dashboard':
            return { ...prevState, dashboard: DashBoard };
          case 'infra':
            return { ...prevState, infra: Infra };
          case 'supTi':
            return { ...prevState, supTi: SupTi };
          case 'sites':
            return { ...prevState, sites: Sites };
          case 'microsoft':
            return { ...prevState, microsoft: Microsoft };
          default:
            return prevState;
        }
      });
    }
  };
  
  return (
    <section className='services-section'>
            
      <div className="services-content">
        <div className='services-content-text'>
          <h2>Nossas</h2>
          <span>Soluções</span>
          <p>A Raitec Soluções em Tecnologia oferece uma ampla gama de serviços para atender às suas necessidades de TI.</p>
          <a href="/contato"><button>Entre em contato</button></a>
        </div>
        <div className="services-text">

          <a href="/servico/microsoft">
            <div className="service-card" onMouseEnter={() => handleMouseEnter('microsoft')} onMouseLeave={() => handleMouseLeave('microsoft')}>
              <div className='img-card5'>
              </div>
              <h3>Microsoft <img src={serviceImages.microsoft} alt="Microsoft" /></h3>
            
              <p>Tenha as<span> ferramentas de produtividade</span>, <span>segurança</span>, <span>escalabilidade</span> e as <span>soluções integradas</span> para impulsionar seu negócio para o sucesso.</p>
              <ButtonServices path="/servico/microsoft" buttonName="Saiba Mais"></ButtonServices>
            
            </div>
          </a>

        </div>
        
      </div>
      <div className="services-cards">
          <a href="/servico/sup-ti">
            <div className="service-card" onMouseEnter={() => handleMouseEnter('supTi')} onMouseLeave={() => handleMouseLeave('supTi')}>
              <div className='img-card3'>
              </div>
              <h3>Suporte TI <img src={serviceImages.supTi} alt="Suporte TI" /></h3>
            
              <p>Serviços de <span>suporte de TI</span> confiáveis e ágeis para manter seus sistemas funcionando sem problemas com uma equipe de <span>especialistas dedicados</span>!</p>
              <ButtonServices path="/servico/sup-ti" buttonName="Saiba Mais"></ButtonServices>
            
            </div>
          </a>
          <a href="/servico/infra">

            <div className="service-card" onMouseEnter={() => handleMouseEnter('infra')} onMouseLeave={() => handleMouseLeave('infra')}>
              <div className='img-card4'>
              </div>
              <h3>Infraestrutura <img src={serviceImages.infra} alt="Infraestrutura" /></h3>
              
              <p><span>Soluções robustas</span> de infraestrutura de TI para garantir a <span>escalabilidade</span>, <span>segurança</span> e <span>desempenho</span> dos seus sistemas empresariais!</p>
              <ButtonServices path="/servico/infra" buttonName="Saiba Mais"></ButtonServices>

              
            </div>
          </a>
          

      </div>
      <div className="services-cards2">
        <a href="/servico/bi">
          <div className="service-card" onMouseEnter={() => handleMouseEnter('dashboard')} onMouseLeave={() => handleMouseLeave('dashboard')}>
            <div className='img-card1'>
            </div>
            <h3><span className='bi-card'>Business Intelligence</span><img src={serviceImages.dashboard} alt="Business Intelligence" /></h3>
          
            <p>Monitore seus <span>processos</span> em tempo real com <span>dashboards, relatórios personalizados</span> e interativos que impulsionam <span>decisões</span> baseadas em <span>dados</span>!</p>
            <ButtonServices path="/servico/bi" buttonName="Saiba Mais"></ButtonServices>
            
          </div>
        </a>

        <a href="/servico/sites">
          <div className="service-card" onMouseEnter={() => handleMouseEnter('sites')} onMouseLeave={() => handleMouseLeave('sites')}>
            <div className='img-card2'>
            </div>
            <h3>Sites <img src={serviceImages.sites} alt="Sites" /></h3>
            
            <p>Crie sua <span>presença online</span> com sites <span>responsivos</span>, otimizados para <span>SEO</span> e projetados para <span>converter</span> visitantes em clientes!</p>
            <ButtonServices path="/servico/sites" buttonName="Saiba Mais"></ButtonServices>

            
          </div>
        </a>
        
        
      </div>
      <div className="services-cards3">
        <a href="/servico/bi">
          <div className="service-card" onMouseEnter={() => handleMouseEnter('dashboard')} onMouseLeave={() => handleMouseLeave('dashboard')}>
              <div className='img-card1'>
              </div>
              <h3><span className='bi-card'>Business Intelligence</span><img src={serviceImages.dashboard} alt="BusinessIntelligence" /></h3>
              
              <p>Monitore seus <span>processos</span> em tempo real com <span>dashboards, relatórios personalizados</span> e interativos que impulsionam <span>decisões</span> baseadas em <span>dados</span>!</p>
              <ButtonServices path="/servico/bi" buttonName="Saiba Mais"></ButtonServices>
            
          </div>
        </a>
      </div>
      <div className="services-cards4">
        <a href="/servico/sites">
          <div className="service-card" onMouseEnter={() => handleMouseEnter('sites')} onMouseLeave={() => handleMouseLeave('sites')}>
                  <div className='img-card2'>
                  </div>
                  <h3>Sites <img src={serviceImages.sites} alt="Sites" /></h3>
                
                  <p>Crie sua <span>presença online</span> com sites <span>responsivos</span>, otimizados para <span>SEO</span> e projetados para <span>converter</span> visitantes em clientes!</p>
                  <ButtonServices path="/servico/sites" buttonName="Saiba Mais"></ButtonServices>
                
          </div>
        </a>
      </div>
      
            
      

      
      
    

    

  </section>

  )
}

export default Solucoes;
