import React, { useState,useEffect  } from 'react';
import ButtonServices from '../../components/ButtonSolutions';
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import HeaderPages from "../../components/HeaderPages";
import SevicosIcon from '../../utils/img/SERVICOS/services-select.png';
import ImgBg from '../../utils/img/SERVICOS/services-img.jpeg';  // Certifique-se de que o caminho está correto!
import EntreEmContato from '../../components/EntreEmContato';
import DashBoard from '../../utils/img/icones/dashboard.png';
import Infra from '../../utils/img/icones/infra.png';
import SupTi from '../../utils/img/icones/sup-ti.png';
import Sites from '../../utils/img/icones/sites.png';
import Microsoft from '../../utils/img/icones/microsoft.png';
import DashBoardSelect from '../../utils/img/icones/dashboard-select.png';
import InfraSelect from '../../utils/img/icones/infra-select.png';
import SupTiSelect from '../../utils/img/icones/sup-ti-select.png';
import SitesSelect from '../../utils/img/icones/sites-select.png';
import MicrosoftSelect from '../../utils/img/icones/microsoft-select.png';
import './Servicos.css'

const Servicos = () => {

  const [serviceImages, setServiceImages] = useState({
    dashboard: DashBoard,
    infra: Infra,
    supTi: SupTi,
    sites: Sites,
    microsoft: Microsoft
  });

  const isLargeScreen = () => window.innerWidth > 770;
  
  useEffect(() => {
    // Update the images based on screen size when component mounts
    updateImages();
    // Listen for resize events to update images accordingly
    window.addEventListener('resize', updateImages);
    // Cleanup function to remove event listener when component unmounts
    return () => window.removeEventListener('resize', updateImages);
  }, []);


  const updateImages = () => {
    const newImages = isLargeScreen()
      ? { 
          dashboard: DashBoard,
          infra: Infra,
          supTi: SupTi,
          sites: Sites,
          microsoft: Microsoft
        }
      : {
          dashboard: DashBoardSelect,
          infra: InfraSelect,
          supTi: SupTiSelect,
          sites: SitesSelect,
          microsoft: MicrosoftSelect
        };
    setServiceImages(newImages);
  };

  const handleMouseEnter = (service) => {
    if (isLargeScreen()) {
      setServiceImages((prevState) => {
        switch (service) {
          case 'dashboard':
            return { ...prevState, dashboard: DashBoardSelect };
          case 'infra':
            return { ...prevState, infra: InfraSelect };
          case 'supTi':
            return { ...prevState, supTi: SupTiSelect };
          case 'sites':
            return { ...prevState, sites: SitesSelect };
          case 'microsoft':
            return { ...prevState, microsoft: MicrosoftSelect };
          default:
            return prevState;
        }
      });
    }
  };

  const handleMouseLeave = (service) => {
    if (isLargeScreen()) {
      setServiceImages((prevState) => {
        switch (service) {
          case 'dashboard':
            return { ...prevState, dashboard: DashBoard };
          case 'infra':
            return { ...prevState, infra: Infra };
          case 'supTi':
            return { ...prevState, supTi: SupTi };
          case 'sites':
            return { ...prevState, sites: Sites };
          case 'microsoft':
            return { ...prevState, microsoft: Microsoft };
          default:
            return prevState;
        }
      });
    }
  };
  
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,  // Usar template string aqui
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center calc(100% - 120px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Serviços" icon={SevicosIcon} description="Conheça nossas Soluções" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
        <section id='solucoes-page'>
          
          <div className="services-content-page">
            <h2>Nossas <span>Soluções</span></h2>
            
            <p>A Raitec Soluções em Tecnologia oferece uma ampla gama de serviços para atender às suas necessidades de TI.</p>
            
          </div>
         
          <div className="solucoes-page-container">

           <a href="/servico/sup-ti">
            <div className="service-card" onMouseEnter={() => handleMouseEnter('supTi')} onMouseLeave={() => handleMouseLeave('supTi')}>
                <div className='img-card3'>
                </div>
                <h3>Suporte TI <img src={serviceImages.supTi} alt="Suporte TI" /></h3>
              
                <p>Serviços de <span>suporte de TI</span> confiáveis e ágeis para manter seus sistemas funcionando sem problemas com uma equipe de <span>especialistas dedicados</span>!</p>
                <ButtonServices path="/servico/sup-ti" buttonName="Saiba Mais"></ButtonServices>
              
              </div>
           </a>

            <a href="/servico/infra">
              <div className="service-card" onMouseEnter={() => handleMouseEnter('infra')} onMouseLeave={() => handleMouseLeave('infra')}>
                <div className='img-card4'>
                </div>
                <h3>Infraestrutura <img src={serviceImages.infra} alt="Infraestrutura" /></h3>
                
                <p><span>Soluções robustas</span> de infraestrutura de TI para garantir a <span>escalabilidade</span>, <span>segurança</span> e <span>desempenho</span> dos seus sistemas empresariais!</p>
                <ButtonServices path="/servico/infra" buttonName="Saiba Mais"></ButtonServices>

                
              </div>
            </a>
           
            <a href="/servico/bi">
              <div className="service-card" onMouseEnter={() => handleMouseEnter('dashboard')} onMouseLeave={() => handleMouseLeave('dashboard')}>
                <div className='img-card1'>
                </div>
                <h3>Business Intelligence <img src={serviceImages.dashboard} alt="Business Intelligence" /></h3>
              
                <p>Monitore seus <span>processos</span> em tempo real com <span>dashboards, relatórios personalizados</span> e interativos que impulsionam <span>decisões</span> baseadas em <span>dados</span>!</p>
                <ButtonServices path="/servico/bi" buttonName="Saiba Mais"></ButtonServices>
                
              </div>
            </a>
            
            <a href="/servico/sites">
              <div className="service-card" onMouseEnter={() => handleMouseEnter('sites')} onMouseLeave={() => handleMouseLeave('sites')}>
                <div className='img-card2'>
                </div>
                <h3>Sites <img src={serviceImages.sites} alt="Sites" /></h3>
                
                <p>Crie sua <span>presença online</span> com sites <span>responsivos</span>, otimizados para <span>SEO</span> e projetados para <span>converter</span> visitantes em clientes!</p>
                <ButtonServices path="/servico/sites" buttonName="Saiba Mais"></ButtonServices>

                
              </div>  
            </a>
           
           <div className='mobile-service'>
            <a href="/servico/microsoft">
                <div className="service-card" onMouseEnter={() => handleMouseEnter('microsoft')} onMouseLeave={() => handleMouseLeave('microsoft')}>
                    <div className='img-card5'>
                    </div>
                    <h3>Microsoft <img src={serviceImages.microsoft} alt="Microsoft" /></h3>
                  
                    <p>Tenha as<span> ferramentas de produtividade</span>, <span>segurança</span>, <span>escalabilidade</span> e as <span>soluções integradas</span> para impulsionar seu negócio para o sucesso.</p>

                    <ButtonServices path="/infra" buttonName="Saiba Mais"></ButtonServices>
                  
                </div>
              </a>
           </div>
            
            
         
          </div>

          <div className='solucoes-page-container2'>
            
            <a href="/servico/microsoft">
              <div className="service-card" onMouseEnter={() => handleMouseEnter('microsoft')} onMouseLeave={() => handleMouseLeave('microsoft')}>
                  <div className='img-card5'>
                  </div>
                  <h3>Microsoft <img src={serviceImages.microsoft} alt="Microsoft" /></h3>
                
                  <p>Tenha as<span> ferramentas de produtividade</span>, <span>segurança</span>, <span>escalabilidade</span> e as <span>soluções integradas</span> para impulsionar seu negócio para o sucesso.</p>

                  <ButtonServices path="/infra" buttonName="Saiba Mais"></ButtonServices>
                
              </div>
            </a>
          </div>
          
        </section>
        <EntreEmContato/>
        <Footer></Footer>
      </main>
      
    </div>
  );
};

export default Servicos
