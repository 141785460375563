import React, { useRef, useEffect, useState } from 'react';
import './SobreNos.css';
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import HeaderPages from "../../components/HeaderPages";
import SobreIcon from '../../utils/img/SOBRE/group-select.png';
import ImgBg from '../../utils/img/SOBRE/header-about.jpeg';  
import EntreEmContato from '../../components/EntreEmContato';
import Missao from '../../utils/img/SOBRE/rocket-img.jpeg';
import Valores from '../../utils/img/SOBRE/values-img.jpeg';
import Visao from '../../utils/img/SOBRE/vision-img.jpeg';
import { FaEye } from "react-icons/fa";
import { IoRocketSharp } from "react-icons/io5";
import { GiCutDiamond } from "react-icons/gi";
import { IoExtensionPuzzle } from "react-icons/io5";
import { IoArrowRedo } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import Tec from '../../utils/img/SOBRE/tec.png';
import Certificado from '../../utils/img/SOBRE/Certificado-select.png';
import Atendimento from '../../utils/img/SOBRE/atendimento.png';

const SobreNos = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const missaoRef = useRef(null);
  const valoresRef1 = useRef(null);
  const valoresRef2 = useRef(null);
  const visaoRef = useRef(null);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleMouseMove = (e, ref) => {
    if (!isLoaded) return;
    if (window.innerWidth <= 610) return; // Verifica o tamanho da tela
    const bg = ref.current;
    const boundingRect = bg.getBoundingClientRect();
    const offsetX = e.clientX - boundingRect.left;
    const offsetY = e.clientY - boundingRect.top;
    const centerX = boundingRect.width / 2;
    const centerY = boundingRect.height / 2;
    const rotateY = (offsetX - centerX) / centerX * 10;
    const rotateX = (centerY - offsetY) / centerY * 10;
    bg.style.transition = 'transform 0.1s';
    bg.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  };
  
  const handleMouseLeave = (ref) => {
    if (!isLoaded) return;
    if (window.innerWidth <= 610) return; // Verifica o tamanho da tela
    const bg = ref.current;
    bg.style.transition = 'transform 1s';
    bg.style.transform = 'perspective(1000px) rotateX(0deg) rotateY(0deg)';
  };
  
  
  

  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,  // Usar template string aqui
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundAttachment = 'none';
    bgStyle.backgroundPosition = 'center';

  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages titleAjust="title-header"  imgBgStyle={bgStyle} title="Sobre Nós" icon={SobreIcon} description="Conheça nossos Valores, Missões e Visão" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />

        <section className='geral-about-us'>
          <section id='texto-principal'>
            <h1>Sua <span>Parceira</span> para o <span>Sucesso Tecnológico</span></h1>
            <p>
              A Raitec Soluções em Tecnologia é uma empresa especializada em fornecer soluções digitais completas para empresas de todos os portes. Com mais de <span>7 anos de experiência</span> e uma equipe de <span>profissionais experientes e apaixonados por tecnologia</span>, ajudamos nossos clientes a alcançarem seus objetivos de negócio através da implementação de soluções inovadoras e eficientes.
            </p>
          </section>

          <section className='esquerda'>
            <h2>
              Nossa <span>Missão</span> <IoRocketSharp className='v-middle'/>
            </h2>
            
            <div className='content-esquerda'>
              <div ref={missaoRef}>
                <img src={Missao}  alt=""  onMouseMove={(e) => handleMouseMove(e, missaoRef)} onMouseLeave={() => handleMouseLeave(missaoRef)} />

              </div>
              <div className='esquerda-text'>
                <ul>
                  <li><IoArrowRedo className='v-middle primary'/>Fornecer <span>soluções digitais</span> de alta qualidade que atendam às <span>necessidades específicas</span> de cada <span>cliente</span>.</li>
                  <li> <IoArrowRedo className='v-middle primary'/><span>Ajudar</span> nossos <span>clientes</span> a <span>alcançarem seus objetivos</span> de negócio através da tecnologia.</li>
                  <li> <IoArrowRedo className='v-middle primary'/>Construir <span>relacionamentos duradouros</span> e de <span>confiança</span> com nossos <span>clientes</span>.</li>
                  <li> <IoArrowRedo className='v-middle primary'/>Ter o <span>Compromisso com a qualidade</span> buscando sempre a <span>excelência</span> em tudo que fazemos, com <span>foco na satisfação</span> total dos nossos <span>clientes</span>.</li>
                </ul>
              </div>
            </div>
          </section>

          <section id='valores'>
            <h2>
              Nossos <span>Valores</span><GiCutDiamond className='v-middle diamond'/>
            </h2>

            <div className='maior'>
              <div className='content-direita '>
                <div className='direita-text'>
                  <ul>
                    <li><FaCheckCircle className='v-middle primary'/><span>Inovação</span>: Buscamos constantemente <span className='destaque-valores'>novas soluções e tecnologias</span> para <span className='destaque-valores'>atender</span> às necessidades dos nossos <span className='destaque-valores'>clientes</span>.</li>
                    <li><FaCheckCircle className='v-middle primary'/><span>Qualidade</span>: Nos comprometemos a <span className='destaque-valores'>entregar soluções</span> de alta qualidade que superam as <span className='destaque-valores'>expectativas</span> dos nossos <span className='destaque-valores'>clientes</span>.</li>
                    <li><FaCheckCircle className='v-middle primary'/><span>Eficiência</span>: Trabalhamos de forma eficiente para <span className='destaque-valores'>oferecer soluções</span> aos nossos clientes no <span className='destaque-valores'>menor tempo possível</span>.</li>
                    <li><FaCheckCircle className='v-middle primary'/><span>Atendimento ao cliente</span>: Priorizamos o atendimento ao cliente e estamos <span className='destaque-valores'>sempre disponíveis</span> para auxiliar nossos clientes em <span className='destaque-valores'>qualquer necessidade</span>.</li>
                  </ul>
                </div>
                <div ref={valoresRef1} >
                  <img className='direita-img' src={Valores} alt="" onMouseMove={(e) => handleMouseMove(e, valoresRef1)} onMouseLeave={() => handleMouseLeave(valoresRef1)} />

                </div>
              </div>
            </div>

            <div className='menor'>
              <div className='content-direita'>
                <div ref={valoresRef2}>
                  <img className='direita-img' src={Valores} alt=""  onMouseMove={(e) => handleMouseMove(e, valoresRef2)} onMouseLeave={() => handleMouseLeave(valoresRef2)} />

                </div>

                <div className='direita-text'>
                  <ul>
                    <li><FaCheckCircle className='v-middle primary'/><span>Inovação</span>: Buscamos constantemente <span className='destaque-valores'>novas soluções e tecnologias</span> para <span className='destaque-valores'>atender</span> às necessidades dos nossos <span className='destaque-valores'>clientes</span>.</li>
                    <li><FaCheckCircle className='v-middle primary'/><span>Qualidade</span>: Nos comprometemos a <span className='destaque-valores'>entregar soluções</span> de alta qualidade que superam as <span className='destaque-valores'>expectativas</span> dos nossos <span className='destaque-valores'>clientes</span>.</li>
                    <li><FaCheckCircle className='v-middle primary'/><span>Eficiência</span>: Trabalhamos de forma eficiente para <span className='destaque-valores'>oferecer soluções</span> aos nossos clientes no <span className='destaque-valores'>menor tempo possível</span>.</li>
                    <li><FaCheckCircle className='v-middle primary'/><span>Atendimento ao cliente</span>: Priorizamos o atendimento ao cliente e estamos <span className='destaque-valores'>sempre disponíveis</span> para auxiliar nossos clientes em <span className='destaque-valores'>qualquer necessidade</span>.</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className='esquerda'>
            <h2>
              Nossa <span>Visão</span>  <FaEye className='v-middle'/>
            </h2>

            <div className='content-esquerda'>
              <div  ref={visaoRef}>
                <img src={Visao} alt="" onMouseMove={(e) => handleMouseMove(e, visaoRef)} onMouseLeave={() => handleMouseLeave(visaoRef)} />
              </div>
              
              <div  className='esquerda-text-visao'>
              Ser reconhecida como líder inovadora no desenvolvimento de <span>soluções digitais personalizadas</span>, transformando o modo como os negócios operam através da <span>tecnologia</span> e construindo um legado de <span>sucesso</span> e <span>confiança</span> com nossos <span>clientes </span>em todo o mundo.
              </div>
            </div>
          </section>

          <section id='diferenciais'>
            <h2>
              Diferenciais <IoExtensionPuzzle className='v-middle title-icon'/>
            </h2>

            <div className='cards-sobre'>
              <div className='card-sobre'>
                <img src={Certificado} alt="" />
                <h3>Equipe especializada  </h3>
                <p>Nossa equipe é formada por profissionais experientes e certificados nas melhores tecnologias do mercado.</p>
              </div>
              <div className='card-sobre'>
                <img src={Atendimento} alt="" />
                <h3>Atendimento personalizado</h3>
                <p>Entendemos as necessidades específicas de cada cliente e oferecemos soluções sob medida.</p>
              </div>
              <div className='card-sobre'>
                <img src={Tec} alt="" />
                <h3>Tecnologia de ponta</h3>
                <p>Utilizamos as melhores ferramentas e recursos do mercado para garantir a qualidade dos nossos serviços.</p>
              </div>
            </div>
          </section>
        </section>

        <EntreEmContato/>
        <Footer />
      </main>
    </div>
  );
};

export default SobreNos;
