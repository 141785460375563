
import React from 'react';
import Include from '../../../components/servicos-card/Include';
import Header from "../../../layout/header/Header";
import Footer from "../../../layout/footer/Footer";
import HeaderPages from "../../../components/HeaderPages";
import ImgBg from '../../../utils/img/INFRA/infra-img.jpeg';  
import EntreEmContato from '../../../components/EntreEmContato';
import InfraIcon from '../../../utils/img/icones/infra-select.png'
import Descricao from '../../../components/servicos-card/Descricao';
import '../Template.css';
import InfraImg from '../../../utils/img/INFRA/infra.png';
import { IoConstructSharp } from "react-icons/io5";
import { BsShieldLockFill } from "react-icons/bs";
import { LuDatabaseBackup } from "react-icons/lu";
import { SiIcloud } from "react-icons/si";
import Beneficios from '../../../components/servicos-card/Beneficios';
import Custos from '../../../utils/img/SUPORTETI/reducao.png';
import Produtividade from '../../../utils/img/SUPORTETI/produtividade.png';
import Seguranca from '../../../utils/img/SUPORTETI/seguranca.png';
import Flexibilidade from '../../../utils/img/SUPORTETI/flexibilidade.png';
import { GiCctvCamera } from "react-icons/gi";
import { TbClock24 } from "react-icons/tb";
import { MdManageSearch } from "react-icons/md";

const Infra = () => {
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,  // Usar template string aqui
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center calc(100% - 150px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Infraestrutura" icon={InfraIcon} description="Conheça nossa Solução"path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
      
        <Descricao titulo='A Base Sólida para o <span>Sucesso</span> Tecnológico do Seu <span>Negócio</span>' gatilho='Como garantir que sua empresa tenha uma infraestrutura de TI robusta e confiável na era digital?' resposta='Na era digital, onde a tecnologia se torna cada vez mais essencial para o sucesso das empresas, contar com uma <span>infraestrutura de TI robusta e confiável</span> é fundamental. A <span>Raitec Soluções em Tecnologia</span> entende essa necessidade e oferece <span>soluções completas</span> para garantir que sua empresa esteja sempre <span>conectada, segura</span> e preparada para os <span>desafios do futuro</span>.' img={InfraImg} pergunta='O que é Infraestrutura de TI?' tituloAssunto='É a Sutentaçao do Sucesso Empresarial'assunto=' A <span>Infraestrutura de TI</span> é a base que sustenta todos os <span>sistemas</span> e <span>processos digitais</span> da sua empresa. Ela é composta por diversos elementos, como <span>hardware</span>, <span>software</span>, <span>redes</span>, <span>data centers</span> e <span>serviços de TI</span>, que trabalham em conjunto para garantir o bom funcionamento das suas operações.'></Descricao>
        
        <section id='service-description'>
          <h2>Nossos serviços em <span>Infraestrutura</span> incluem:</h2>
          <ul>
            <Include icon={<MdManageSearch />} titulo='Consultoria em Infraestrutura de TI: ' texto='Avaliaremos suas <span>necessidades atuais e futuras</span> e te ajudaremos a definir a <span>estratégia ideal</span> para sua <span>infraestrutura de TI</span>.'></Include>
            <Include icon={<IoConstructSharp />} titulo='Projeto e Implementação de Infraestrutura de TI: ' texto='Projetaremos e implementaremos uma <span>infraestrutura de TI</span> robusta, segura e escalável, utilizando as <span>melhores tecnologias</span> do mercado.'></Include>
            <Include icon={<GiCctvCamera />} titulo='Instalações de Câmeras de Segurança: ' texto='Projetaremos e implementaremos <span>instalações de câmeras de segurança</span>, utilizando as <span>melhores tecnologias</span> do mercado.' ></Include>
            <Include icon={<TbClock24 />} titulo='Gerenciamento de Infraestrutura de TI: ' texto='<span>Monitoraremos</span>, <span>gerenciaremos</span> e <span>manteremos sua infraestrutura de TI</span> 24 horas por dia, 7 dias por semana, garantindo o seu bom funcionamento.'></Include>
            <Include icon={<SiIcloud />} titulo='Soluções em Cloud Computing: ' texto='<span>Auxiliamos na implementação</span>, <span>migração</span> e <span>gerenciamento de soluções em cloud computing</span>, como <span>Microsoft Azure</span>, <span>Amazon Web Services</span> e <span>Google Cloud Platform</span>.'></Include>
            <Include icon={<BsShieldLockFill />} titulo='Segurança da Informação: ' texto='<span>Implementamos medidas de segurança</span> para proteger sua <span>infraestrutura de TI</span> contra <span>ataques cibernéticos</span>, <span>malwares</span> e outras <span>ameaças</span>.'></Include>
            <Include icon={<LuDatabaseBackup />} titulo='Backup e Recuperação de Desastres: ' texto='<span>Garantimos a segurança dos seus dados</span> com soluções de <span>backup</span> e <span>recuperação de desastres</span> confiáveis.'></Include>
            
          </ul>
        </section>

        <section id='beneficios'>
          <h2>
          Com a <span>Raitec</span>, você terá:
          </h2>
          <h4 className='pc-beneficios'>
            Passe o cursor do mouse em cima dos cards para saber mais
          </h4>
          <h4 className='mobile-beneficios'>
            Clique em cima dos cards para saber mais
          </h4>
          <div className='container-beneficios'>
            <Beneficios titulo='Aumento da produtividade' texto='Com <span>sistemas estáveis e confiáveis</span>, seus funcionários podem trabalhar sem interrupções, focando no que realmente importa: <span>gerar resultados</span>.' bgImage={Produtividade}/>
            
            <Beneficios titulo='Melhoria da segurança da informação' texto='Uma <span>infraestrutura segura</span> protege seus <span>dados</span> contra <span>acessos não autorizados</span>, <span>ataques cibernéticos</span> e <span>perda de informações confidenciais</span>.' bgImage={Seguranca}/>

            <Beneficios titulo='Redução de custos' texto='Ao <span>otimizar o uso de recursos</span> e <span>prevenir problemas</span>, você pode <span>reduzir custos</span> com <span>manutenção</span>, <span>reparos</span> e <span>tempo de inatividade</span>.' bgImage={Custos}/>

            <Beneficios titulo='Maior escalabilidade' texto='Uma <span>infraestrutura flexível</span> permite que sua empresa <span>cresça</span> sem precisar investir em <span>novos equipamentos</span> ou <span>softwares</span> a cada momento.' bgImage={Flexibilidade}/>


          </div>
        </section>

        <EntreEmContato/>
        <Footer></Footer> 

      </main>
    </div>
  );
};
export default Infra
