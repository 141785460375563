// CustomArrow.js
import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './CustomArrow.css'; // Ensure you have a CSS file for these styles

export const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <IoIosArrowBack
      className={`slick-prev custom-arrow ${className}`}
      style={{ ...style, display: 'block' }} // Make sure the arrow is always displayed
      onClick={onClick}
    />
  );
};

export const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <IoIosArrowForward
      className={`slick-next custom-arrow ${className}`}
      style={{ ...style, display: 'block' }} // Make sure the arrow is always displayed
      onClick={onClick}
    />
  );
};
