import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Clients.css';
import Pollysoft from '../../../utils/img/clientes/polly-logo-contato.png';
import Unipreco from '../../../utils/img/clientes/unipreco.png';
import Grafica from '../../../utils/img/clientes/grafica-vitrine.png';
import Neuro from '../../../utils/img/clientes/neuro-intensiva.png';
import SeTech from '../../../utils/img/clientes/setech.png';
import MundoISP from '../../../utils/img/clientes/mundo-isplan.png';
import { CustomPrevArrow, CustomNextArrow } from './CustomArrow';

const Clients = () => {
    const clientSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1250, // At or below 1250px
                settings: {
                    slidesToShow: 3, // Show 2 slides instead of 4
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768, // Another breakpoint for smaller devices
                settings: {
                    slidesToShow: 2, // Show 1 slide for very small devices
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section id='clients'>
            <h2 className='section-title'>Nossos <span className='destaque'>Clientes</span></h2>
            <div className='divsor'>
               <a href="/clientes"> 
                    <Slider {...clientSettings}>
                        <div className='client'><img src={Grafica} alt="" /></div>
                        <div className='client'><img src={Unipreco} alt="" /></div>
                        <div className='client isp'><img src={MundoISP} alt="" /></div>
                        <div className='client'><img src={SeTech} alt="" /></div>
                        <div className='client neuro'><img src={Neuro} alt="" /></div>
                        <div className='client polly'><img src={Pollysoft} alt="" /></div>
                    </Slider>
                </a>
                <a href="/clientes"><button>Saiba Mais</button></a>
            </div>
        </section>
    )
}

export default Clients;
