import React from 'react'
import './Cliente.css'
import Header from "../../layout/header/Header";
import ImgBg from "../../utils/img/clientes/client-img.jpeg";
import HeaderPages from "../../components/HeaderPages";
import ClientesIcon from "../../utils/img/clientes/clientes-select.png";
import Footer from "../../layout/footer/Footer";
import EntreEmContato from '../../components/EntreEmContato';
import Pollysoft from '../../utils/img/clientes/polly-logo-contato.png';
import Unipreco from '../../utils/img/clientes/unipreco.png';
import Grafica from '../../utils/img/clientes/grafica-vitrine.png';
import Neuro from '../../utils/img/clientes/neuro-intensiva.png';
import SeTech from '../../utils/img/clientes/setech.png';
import MundoISP from '../../utils/img/clientes/mundo-isplan.png';


const Clientes = () => {
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,  // Usar template string aqui
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center calc(90% - 60px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='clientes'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Clientes" icon={ClientesIcon} description="Conheça nossos Cases" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
        <section className='clientes-page'>
          <div className='text-cliente-page'>
            <h1>
              Nossos <span>Clientes</span>
            </h1>
            <p>
              A Raitec possui uma carteira de clientes diversificada, que inclui <span>empresas de todos os portes</span>. Atendemos desde pequenas empresas até grandes corporações, em <span>diversos setores da economia</span>.  Veja os serviços feitos nos nossos cases:
            </p>
          </div>
          <div className='cards-clientes'>
            <div className='card-client-page'>
              <div className='img-cards'>
                <img className='mundo-isp' src={MundoISP} alt="" />
              </div>
              <h4 className='tipo-servico'> <span className='supti-s'>Sup Ti</span> <span className='infra-s'>Infra</span></h4>
              <div className='descricao-cliente'>
              <p> Implementação de <span>firewalls</span>, migração para <span>telefonia IP</span>, automação de <span>backups</span>, gestão de <span>acessos</span> e <span>redes</span> para redução de custos, aumento de <span>segurança</span> e <span>eficiência</span>.</p>

              </div>
            </div>
            <div className='card-client-page'>
              <div className='img-cards'>
                <img className='setech' src={SeTech} alt="" />
              </div>
              <h4 className='tipo-servico'> <span className='supti-s'>Sup Ti</span> <span className='infra-s'>Infra</span> <span className='bi-s'>BI</span></h4>
              
              <div className='descricao-cliente'>
              <p>  <span>Gestão de TI</span>, <span>suporte técnico especializado</span>, <span>Business Intelligence</span> para decisões estratégicas, <span>infraestrutura robusta</span> e <span>soluções personalizadas</span> garantem crescimento, <span>segurança</span> e <span>produtividade</span>.</p>
              </div>
            </div>
            <div className='card-client-page'>
              <div className='img-cards'>
                <img className='neuro' src={Neuro} alt="" />
              </div>
              <h4 className='tipo-servico'> <span className='supti-s'>Sup Ti</span> <span className='infra-s'>Infra</span> </h4>
              <div className='descricao-cliente'>
                <p>Instalação de <span>mais de 160 câmeras CFTV</span>, <span>servidores robustos</span>, <span>gestão de acesso</span>, manutenção de máquinas, e <span>atualização de softwares</span> garantem <span>segurança</span> e <span>eficiência operacional</span>.</p>
              </div>
            </div>
            <div className='card-client-page'>

              <div className='img-cards'>
                <img className='grafica' src={Grafica} alt="" />
              </div>
              <h4 className='tipo-servico'> <span className='supti-s'>Sup Ti</span> <span className='infra-s'>Infra</span></h4>
              <div className='descricao-cliente'>
                <p><span>Reformulação do servidor de arquivos</span>, <span>implantação de firewall UTM/NGFW</span> com roteamento entre redes. Suporte a equipamentos e rede de computadores. <span>Ampliação e melhoria na infra das câmeras de segurança</span>.</p>
              </div>
            </div>
            <div className='card-client-page'>
            <div className='img-cards'>
              <img className='unipreco' src={Unipreco} alt="" />
            </div>
                          
              <h4 className='tipo-servico'> <span className='supti-s'>Sup Ti</span> <span className='infra-s'>Infra</span></h4>
              <div className='descricao-cliente'>
                <p>Realizada <span>implantação de firewall UTM/NGFW</span> com filtro de proxy para navegação, regras de roteamento entre unidades e <span>VPN</span> com <span>OpenVPN e IPSec</span>.</p>
              </div>
            </div>
            <div className='card-client-page'>
              <div className='img-cards'>
                <img className='pollysoft' src={Pollysoft} alt="" />
              </div>
              <h4 className='tipo-servico'> <span className='supti-s'>Sup Ti</span> <span className='infra-s'>Infra</span></h4>
              <div className='descricao-cliente'>
                <p><span>Otimização da infraestrutura</span>, <span>automação de processos</span>, <span>ferramentas de monitoramento</span>, <span>medidas robustas de segurança</span>, e <span>suporte técnico proativo</span> para desempenho, confiabilidade e segurança.</p>
              </div>
            </div>
          </div>
        </section>
        <EntreEmContato/>
        <Footer></Footer> 
      </main>
      
    </div>
  );
};

export default Clientes
