import React from 'react';
import './Privacidade.css';
import Header from "../../layout/header/Header";
import ImgBg from "../../utils/img/privacity/terms-img.png";
import HeaderPages from "../../components/HeaderPages";
import PrivacityIcon from "../../utils/img/privacity/terms-select.png";
import Footer from "../../layout/footer/Footer";
import EntreEmContato from '../../components/EntreEmContato';


const Privacidade = () => {
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,  // Usar template string aqui
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'blur(2px)',
    backgroundPosition: 'center calc(90% - 60px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Privacidade" icon={PrivacityIcon} description="Conheça a Política de Privacidade" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
        
        <section className='section-privacidade'>
          <h1 className='title-privacidade'>Política de Privacidade</h1>
          <p>A Raitec Soluções em Tecnologia está comprometida em proteger a privacidade de seus usuários ("você", "seu"). Esta Política de Privacidade ("Política") descreve como coletamos, usamos e divulgamos suas informações pessoais quando você usa nossos sites, aplicativos e serviços ("Serviços").</p>
          <h2>1. Coleta de Informações Pessoais</h2>
          <p>Coletamos informações pessoais de você quando você usa nossos Serviços, incluindo:</p>
          <ul>
            <li>Informações de contato: Nome, endereço de e-mail, número de telefone.</li>
            <li>Informações de conta: Nome de usuário, senha, informações de pagamento.</li>
            <li>Dados de uso: Endereço IP, navegador da web, sistema operacional, data e hora do uso, páginas visitadas.</li>
            <li>Conteúdo gerado pelo usuário: Comentários, avaliações, mensagens.</li>
          </ul>
          <p>Também podemos coletar informações pessoais de fontes terceiras, como:</p>
          <ul>
            <li>Redes sociais: Quando você conecta sua conta da Raitec a uma rede social, podemos coletar informações do seu perfil, como nome, foto e lista de amigos.</li>
            <li>Parceiros de negócios: Podemos receber informações pessoais de parceiros de negócios, como provedores de serviços de pagamento ou empresas de marketing.</li>
          </ul>
          <h2>2. Uso de Informações Pessoais</h2>
          <p>Usamos suas informações pessoais para:</p>
          <ul>
            <li>Fornecer e melhorar nossos Serviços: Usamos suas informações pessoais para fornecer os Serviços que você solicita e para melhorar a qualidade dos nossos Serviços.</li>
            <li>Comunicar-se com você: Usamos suas informações pessoais para enviar e-mails, notificações e outras comunicações sobre nossos Serviços e sua conta.</li>
            <li>Fins de marketing: Podemos usar suas informações pessoais para enviar e-mails de marketing e promoções. Você pode optar por não receber esses e-mails a qualquer momento clicando no link de cancelamento de inscrição na parte inferior do e-mail.</li>
            <li>Segurança e prevenção de fraude: Podemos usar suas informações pessoais para proteger nossos Serviços e prevenir fraudes.</li>
          </ul>
          <h2>3. Compartilhamento de Informações Pessoais</h2>
          <p>Podemos compartilhar suas informações pessoais com:</p>
          <ul>
            <li>Provedores de serviços: Podemos compartilhar suas informações pessoais com provedores de serviços que nos ajudam a operar nossos Serviços, como provedores de hospedagem, provedores de pagamento e empresas de marketing.</li>
            <li>Autoridades legais: Podemos compartilhar suas informações pessoais com autoridades legais se forem obrigadas a fazê-lo por lei ou se acreditarmos de boa fé que tal ação seja necessária para proteger nossos direitos ou os direitos de terceiros.</li>
          </ul>
          <h2>4. Segurança de Informações Pessoais</h2>
          <p>Tomamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, uso, divulgação, alteração ou destruição. Essas medidas incluem:</p>
          <ul>
            <li>Controle de acesso físico e eletrônico aos nossos sistemas.</li>
            <li>Criptografia de dados em repouso e em trânsito.</li>
            <li>Monitoramento de nossos sistemas para detectar atividades suspeitas.</li>
          </ul>
          <h2>5. Suas Escolhas</h2>
          <p>Você tem as seguintes opções em relação às suas informações pessoais:</p>
          <ul>
            <li>Acesso: Você pode solicitar acesso às suas informações pessoais que coletamos.</li>
            <li>Correção: Você pode solicitar a correção de suas informações pessoais que sejam incorretas ou incompletas.</li>
            <li>Exclusão: Você pode solicitar a exclusão de suas informações pessoais.</li>
            <li>Restrição: Você pode solicitar que restrinjamos o processamento de suas informações pessoais.</li>
            <li>Portabilidade: Você pode solicitar que forneçamos suas informações pessoais em um formato estruturado, comum e legível por máquina.</li>
            <li>Oposição: Você pode se opor ao processamento de suas informações pessoais para fins de marketing direto.</li>
          </ul>
          <h2>6. Retenção de Informações Pessoais</h2>
          <p>Retemos suas informações pessoais pelo tempo necessário para fornecer os Serviços, cumprir nossas obrigações legais e contratuais e resolver disputas.</p>
          <h2>7. Alterações nesta Política</h2>
          <p>Podemos atualizar esta Política de tempos em tempos. Notificaremos você sobre qualquer alteração significativa nesta Política por meio de e-mail ou publicando um aviso em nossos Serviços.</p>
          <h2>8. Contato</h2>
          <p>Se você tiver alguma dúvida sobre esta Política, entre em contato conosco pelo e-mail contato@raitec.com.br</p>
          <h2>9. Lei Aplicável e Foro</h2>
          <p>Esta Política é regida e interpretada de acordo com as leis do Brasil. Qualquer disputa que surja desta Política será submetida à jurisdição exclusiva dos tribunais do Brasil.</p>
          <h2>10. Termos Adicionais</h2>
          <p>Nossos Serviços podem ser regidos por termos adicionais, como Termos de Uso ou Contratos de Serviços. Esses termos adicionais incorporam esta Política por referência.</p>
          <p>Ao usar nossos Serviços, você concorda com esta Política de Privacidade. Se você não concordar com esta Política, não use nossos Serviços.</p>
        </section>

        <div className='space-priv'>

        </div>
        <EntreEmContato />

        <Footer />
      </main>
      
       
    </div>
  );
};

export default Privacidade;
