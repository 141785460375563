import React from 'react';
import Logo from '../../utils/img/logo.png';
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa6";
import { ImWhatsapp } from "react-icons/im";

import './Footer.css';

const Footer = () => {
  return (
    
    <footer>
        <div id="footer_content">
            <div id="footer_contacts">
                <p><img className='footer-logo' src={Logo} alt="" /></p>
                

                <div id="footer_social_media">
                <a href="https://www.instagram.com/raitectecnologia/" className="footer-link" id="instagram" target="_blank" rel="noopener noreferrer">
                    <GrInstagram className='i'/>
                </a>
                <a href="https://www.facebook.com/raiteccuritiba/?locale=pt_BR" className="footer-link" id="facebook" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF className='i'/>
                </a>
                <a href="https://api.whatsapp.com/send?phone=5541987371760" className="footer-link" id="whatsapp" target="_blank" rel="noopener noreferrer">
                    <ImWhatsapp className='i'/>
                </a>

                </div>
            </div>

            <ul className='footer-list'>
                <li>
                    <h3>A Empresa</h3>
                </li>
                <li>
                    <a href="/sobre-nos" className='footer-link'>Sobre Nós</a>
                </li>
                <li>
                    <a href="/servicos" className='footer-link'>Soluções</a>
                </li>
                <li>
                    <a href="/clientes" className='footer-link'>Clientes</a>
                </li>
                <li>
                    <a href="/privacidade" className='footer-link'>Privacidade</a>
                </li>
                
            </ul>

            <ul className='footer-list'>
                <li>
                    <h3>Serviços</h3>
                </li>
                <li>
                    <a href="/servico/bi" className='footer-link'>Business Intelligence (BI)</a>
                </li>
                <li>
                    <a href="/servico/infra" className='footer-link'>Infraestrutura de TI</a>
                </li>
                <li>
                    <a href="/servico/sup-ti" className='footer-link'>Suporte Técnico</a>
                </li>
                <li>
                    <a href="/servico/sites" className='footer-link'>Desenvolvimento de Sites</a>
                </li>
                <li>
                    <a href="/servico/microsoft" className='footer-link'>Microsoft</a>
                </li>
                
            </ul>

            <ul className='footer-list'>
                <li>
                    <h3>Contato</h3>
                </li>
                <li>
                    <a href="https://api.whatsapp.com/send?phone=5541987371760" className='footer-link' target="_blank" rel="noopener noreferrer">Entre em contato:</a>
                </li>
                <li>
                    <a href="https://api.whatsapp.com/send?phone=5541987371760" className='footer-link' target="_blank" rel="noopener noreferrer">(41) 98737-1760</a>
                </li>
                <li>
                    <a href="https://api.whatsapp.com/send?phone=5541995324945" className='footer-link' target="_blank" rel="noopener noreferrer">(41) 99532-4945</a>
                </li>

            </ul>
        </div>
        <div id='footer_copyright'>
            ©
            2017 - 2024 Raitec Soluções em Tecnologia
        </div>
    </footer>
    
  )
}

export default Footer
