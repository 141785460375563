import React from 'react'
import './Descricao.css'
import Button from '../Button'

const Descricao = (props) => {
  return (

    <div>
      <section id='description-page'>
          
        <h1 className='titulo-description' dangerouslySetInnerHTML={{ __html: props.titulo }}>
            
        </h1>
        
        <p className='pergunta-description'>{props.gatilho}</p>

        <p className='resposta-description' dangerouslySetInnerHTML={{ __html: props.resposta }}></p>
        
            
      </section>

      <section id='description-conatainer-page'>
        <div  className='container-description'>
            <div className='description-container'>

              <div>
                <img src={props.img}className='description-img'/>
              </div>

              <div className='description-content'>
                <h2 className='description-container-title'>{props.pergunta}</h2>
                <h1>{props.tituloAssunto}</h1>
                <p dangerouslySetInnerHTML={{ __html: props.assunto }}></p>
                <Button className="button-description" path="https://api.whatsapp.com/send?phone=5541987371760" description="Peça seu orçamento" />
              </div>

            </div>
          </div>
      </section>
    </div>
  )
}

export default Descricao
