import React from 'react';
import './Include.css';

const Include = (props) => {
    const combinedText = `<span class='titulo-include'>${props.titulo}</span> ${props.texto}`;

    return (
        <div className='container-include'>
            <div className='icon-include'>
                {props.icon}
            </div>
            <div>
                <p className='text-include' dangerouslySetInnerHTML={{ __html: combinedText }}></p>
            </div>
        </div>
    );
}

export default Include;
