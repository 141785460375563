import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from '../../utils/img/logo.png';  
import { GrInstagram } from "react-icons/gr";
import { FaFacebookSquare } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shadow, setshadow] = useState(false);

  const toggleMenu = () => {
    setshadow(!shadow);
    setIsMenuOpen(!isMenuOpen);

  };

  const hideMobileMenuOnLargeScreen = () => {
    const screenWidth = window.innerWidth;
    const mobileMenu = document.querySelector('.mobile-menu');
    if (screenWidth > 1111 && isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // Adicione um ouvinte de evento para o redimensionamento da janela
    window.addEventListener('resize', hideMobileMenuOnLargeScreen);

    // Função de limpeza para remover o ouvinte de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', hideMobileMenuOnLargeScreen);
    };
  }, [isMenuOpen]); // A dependência 'isMenuOpen' garante que o efeito será executado sempre que 'isMenuOpen' mudar


  return (
    <header className='header'>
        <nav className={`nav-bar ${shadow ? 'shadow' : ''}`}>
            <div className='logo'>
                <a href="/"><img src={Logo} alt="" /></a>
            </div>
            
            <div className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li className='nav-item'><a className='nav-link' href="/">INÍCIO</a></li>
                    <li className='nav-item'><a className='nav-link' href="/sobre-nos">SOBRE NÓS</a></li>
                    <li className='nav-item dropdown'>
                        <a className='nav-link servico' href="/servicos">SERVIÇOS <IoMdArrowDropdown/></a>
                        <div className='dropdown-menu'>
                            <a className='nav-link-sub' href="/servico/bi">Business Intelligence</a>
                            <a className='nav-link-sub' href="/servico/infra">Infraestrutura</a>
                            <a className='nav-link-sub' href="/servico/sup-ti">Suporte TI</a>
                            <a className='nav-link-sub' href="/servico/sites">Sites</a>
                            <a className='nav-link-sub' href="/servico/microsoft">Microsoft</a>
                        </div>
                    </li>
                    <li className='nav-item'><a className='nav-link' href="/clientes">CLIENTES</a></li>
                    <li className='nav-item'><a className='nav-link' href="/contato">CONTATO</a></li>
                    <li className='nav-item mid'><a className='nav-link' href="https://api.whatsapp.com/send?phone=5541987371760" target="_blank" rel="noopener noreferrer"><BsWhatsapp className='social_media logo'/> </a></li>
                    <li className='nav-item mid'><a className='nav-link' href="https://www.instagram.com/raitectecnologia/" target="_blank" rel="noopener noreferrer"><GrInstagram className='social_media logo'/></a></li>
                    <li className='nav-item mid'><a className='nav-link' href="https://www.facebook.com/raiteccuritiba/?locale=pt_BR" target="_blank" rel="noopener noreferrer"><FaFacebookSquare className='social_media logo'/></a></li>
                </ul>
            </div>

            

            <div className={`mobile-menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>  
        </nav>

        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
            <ul>
                <li className='nav-item'><a className='nav-link' href="/">INÍCIO</a></li>
                <li className='nav-item'><a className='nav-link' href="/sobre-nos">SOBRE NÓS</a></li>
                <li className='nav-item'><a className='nav-link' href="/servicos">SERVIÇOS</a></li>
                <li className='nav-item'><a className='nav-link' href="/clientes">CLIENTES</a></li>
                <li className='nav-item'><a className='nav-link' href="/contato">CONTATO</a></li>
            </ul>
        </div>
    </header>
  );
}

export default Header;
