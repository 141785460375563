
import React from 'react';
import Header from "../../../layout/header/Header";
import Footer from "../../../layout/footer/Footer";
import HeaderPages from "../../../components/HeaderPages";
import DashboardIcon from '../../../utils/img/icones/dashboard-select.png';
import EntreEmContato from '../../../components/EntreEmContato';
import ImgBg from '../../../utils/img/DASHBOARDS/dashboard-bg.jpeg';  
import Descricao from '../../../components/servicos-card/Descricao';
import BiImg from '../../../utils/img/DASHBOARDS/bi.jpeg';
import '../Template.css'
import { GiTalk } from "react-icons/gi";
import { FaGears } from "react-icons/fa6";
import { BsGraphUpArrow } from "react-icons/bs";
import { GiMagnifyingGlass } from "react-icons/gi";
import { FaGraduationCap } from "react-icons/fa6";
import Include from '../../../components/servicos-card/Include';
import Beneficios from '../../../components/servicos-card/Beneficios';
import Decisao from '../../../utils/img/DASHBOARDS/decisao.png';
import Competitividade from '../../../utils/img/DASHBOARDS/competitividade.png';
import Produtos from '../../../utils/img/DASHBOARDS/produtos.png';
import Reducao from '../../../utils/img/DASHBOARDS/reducao.png';
import Produtitivide from '../../../utils/img/DASHBOARDS/produtividade.png';



const Bi = () => {
  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center calc(100% - 120px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundPosition = 'center';
    bgStyle.backgroundAttachment = 'none';
  }

  return (
    <div>
      <Header />
      <main id='sobre'>
        <HeaderPages imgBgStyle={bgStyle} title="Business Intelligence" titleAjust="title-header-title-ajust" icon={DashboardIcon} description="Conheça nossa Solução" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
      
        <Descricao titulo='Transforme Seus <span>Dados</span> em <span>Decisões Inteligentes</span>' gatilho='Cansado de tomar decisões com base em intuição?' resposta='Na era da <span>informação</span>, empresas que desejam se destacar no mercado precisam ir além da <span>intuição</span> e tomar <span>decisões estratégicas</span> baseadas em <span>dados concretos</span>. O <span>Business Intelligence (BI)</span> oferece as <span>ferramentas</span> e <span>técnicas</span> necessárias para transformar seus dados em <span>insights valiosos</span> que impulsionam o <span>crescimento</span> do seu negócio.' img={BiImg} pergunta='O que é Business Intelligence (BI)?' tituloAssunto='É Capacitação de Decisões Estratégicas'assunto='O <span>Business Intelligence (BI)</span> é um conjunto de <span>ferramentas</span> e <span>tecnologias</span> que permitem <span>coletar</span>, <span>organizar</span>, <span>analisar</span> e <span>visualizar</span> dados de diversas fontes, como sistemas de <span>vendas</span>, <span>marketing</span>, <span>produção</span> e <span>finanças</span>. Com o BI, você pode gerar <span>relatórios</span>, <span>dashboards</span> e outros <span>indicadores</span> que fornecem uma <span>visão completa</span> do seu negócio.'></Descricao>
    

        <section id='service-description'>
          <h2>Nossos serviços em <span>Business Intelligence</span> incluem:</h2>
          <ul>
            <Include icon={<GiTalk />} titulo='Consultoria em BI: ' texto='Avaliaremos suas <span>necessidades</span> e te ajudaremos a definir a <span>estratégia ideal</span> para a <span>implementação</span> de <span>BI</span> em sua empresa.'></Include>
            <Include icon={<FaGears />} titulo='Implementação de soluções de BI: ' texto='Implementaremos as <span>ferramentas</span> e <span>tecnologias</span> necessárias para que você possa <span>coletar</span>, <span>organizar</span>, <span>analisar</span> e <span>visualizar</span> seus dados.'></Include>
            <Include icon={<BsGraphUpArrow />} titulo='Desenvolvimento de dashboards e relatórios: ' texto='Criaremos <span>dashboards</span> e <span>relatórios personalizados</span> que fornecem uma <span>visão completa</span> do seu negócio.'></Include>
            <Include icon={<GiMagnifyingGlass />} titulo='Análise de dados: ' texto='Realizamos <span>atualizações de segurança</span> regulares para <span>proteger sua empresa</span> contra as últimas ameaças cibernéticas, garantindo a confidencialidade dos seus dados.'></Include>
            <Include icon={<FaGraduationCap />} titulo='Treinamento: ' texto='Treinaremos sua <span>equipe</span> para dominar as <span>ferramentas de BI</span>, garantindo uso <span>eficiente</span> e eficaz, crucial para otimizar a tomada de decisões na sua empresa.'></Include>
        
          </ul>
        </section>

        <section id='beneficios'>
          <h2>
          Com a <span>Raitec</span>, você terá:
          </h2>
          <h4 className='pc-beneficios'>
            Passe o cursor do mouse em cima dos cards para saber mais
          </h4>
          <h4 className='mobile-beneficios'>
            Clique em cima dos cards para saber mais
          </h4>
          <div className='container-beneficios'>
            <Beneficios titulo='Melhora na tomada de decisões' texto='Com <span>dados concretos</span> à disposição, você pode tomar decisões mais <span>assertivas</span> e <span>estratégicas</span>, aumentando as chances de <span>sucesso</span> do seu negócio.' bgImage={Decisao}/>
            <Beneficios titulo='Aumento da competitividade' texto='As empresas que utilizam <span>BI</span> estão à frente da concorrência, pois possuem uma <span>visão mais profunda</span> do mercado e dos seus <span>clientes</span>.' bgImage={Competitividade}/>
            <Beneficios titulo='Redução de custos' texto='Ao identificar <span>áreas de ineficiência</span> e <span>desperdício</span>, você pode <span>reduzir custos</span> e <span>otimizar</span> seus processos.' bgImage={Reducao}/>
            <Beneficios titulo='Melhoria da performance' texto='O <span>BI</span> pode te ajudar a aumentar a <span>produtividade</span>, a <span>eficiência</span> e a <span>lucratividade</span> da sua empresa.' bgImage={Produtitivide}/>
            <Beneficios titulo='Novos produtos e serviços' texto='Através da <span>análise de dados</span>, você pode identificar <span>novas oportunidades de mercado</span> e desenvolver <span>produtos e serviços</span> que atendam às <span>necessidades dos seus clientes</span>.' bgImage={Produtos}/>

          </div>
        </section>


        <EntreEmContato/>
        <Footer></Footer> 
      
      </main>
      
    </div>
  );
};
export default Bi
