import React, { useState } from 'react';
import $ from 'jquery';
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import ImgBg from '../../utils/img/CONTATO/contact-img.jpeg';
import ContatoIcon from '../../utils/img/CONTATO/telefone-select.png';
import HeaderPages from "../../components/HeaderPages";
import { FaFacebookSquare, FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { MdOutlineEmail } from "react-icons/md";
import LogoEnvio from '../../utils/img/logo-main-removebg.png';
import Carregando from '../../utils/img/CONTATO/carregando.gif';
import './Contato.css';

const Contato = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    nameCompany: '',
    cargo: '',
    msg: ''
  });
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setIsVisible(false);
  };

  const handlePhoneInput = (event) => {
    const input = event.target.value.replace(/\D/g, '');
    let formattedPhoneNumber = '';

    if (input.length > 0) {
      formattedPhoneNumber = `(${input.slice(0, 2)}`;
      if (input.length >= 3) {
        formattedPhoneNumber += `) ${input.slice(2, 6)}`;
        if (input.length >= 7) {
          formattedPhoneNumber += `-${input.slice(6)}`;
          if (input.length === 11) {
            formattedPhoneNumber = `(${input.slice(0, 2)}) ${input.slice(2, 7)}-${input.slice(7)}`;
          }
        }
      }
    }

    setFormData(prev => ({ ...prev, phoneNumber: formattedPhoneNumber }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "phoneNumber") {
      handlePhoneInput(e);
    } else {
      setFormData(prev => ({ ...prev, [id]: value }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const telRegex = /^(\([1-9]{2}\) )?[0-9]{4,5}-[0-9]{4}$/;

    if (!formData.name.trim()) {
      newErrors.name = 'Nome obrigatório';
    }
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = 'Obrigatório e deve ser válido';
    }
    if (!formData.phoneNumber.trim() || !telRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Obrigatório e deve estar no formato correto';
    }
    if (!formData.msg.trim()) {
      newErrors.msg = 'Mensagem obrigatória';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      const botaoEnviar = document.getElementById('enviar-form');
      botaoEnviar.disabled = true;
      document.getElementById('div-botao').classList.add('disabled-style');

      $.ajax({
        url: 'prepara.php',
        method: 'POST',
        data: {
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          nameCompany: formData.nameCompany,
          cargo: formData.cargo,
          msg: formData.msg
        },
        success: function() {
          setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            nameCompany: '',
            cargo: '',
            msg: ''
          });
          botaoEnviar.disabled = false;
          document.getElementById('div-botao').classList.remove('disabled-style');
          setIsSubmitting(false);
          setIsVisible(true);

          $('#resultPopup').fadeIn();
          $('#closePopup').on('click', function() {
            $('#resultPopup').fadeOut();
          });
        },
        error: function(error) {
          botaoEnviar.disabled = false;
          document.getElementById('div-botao').classList.remove('disabled-style');
          console.error('Erro ao enviar e-mail:', error);
          setIsSubmitting(false);
        }
      });
    }
  };

  const bgStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1), transparent), url(${ImgBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'blur(2px)',
    backgroundPosition: 'center calc(65% - 50px)',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  if (window.innerWidth <= 610) {
    bgStyle.backgroundAttachment = 'none';
    bgStyle.backgroundPosition = 'center';
  }

  return (
    <div className="contact-container-page">
      <Header />
      <main id='contact-page'>
        <HeaderPages titleAjust="title-header" imgBgStyle={bgStyle} title="Contato" icon={ContatoIcon} description="Estamos prontos para te atender" path="https://api.whatsapp.com/send?phone=5541987371760" buttonName="Agende uma Reunião" />
        <div className="contact-content-page">
          <div className="contact-info-page">
            <div>
              <h3>CONTATO</h3>
              <h1>Entre em contato!</h1>  
            </div>
            <p>Temos uma equipe especializada para atender as mais diversas demandas na área de TI. Entre em contato via formulário, Whatsapp ou ligando.</p>
            <div className='how-contact'>
              <p className="contact-email-page"><MdOutlineEmail className='v-middle'/>contato@raitec.com.br</p>
              <p className="contact-phone-page"><FaPhoneAlt className='v-middle' />(41) 98737-1760</p>
            </div>
            <div className='redes-sociais-form'>
              <p>Redes Sociais:</p>
              <span><a href="https://www.facebook.com/raiteccuritiba/?locale=pt_BR" target="_blank" rel="noopener noreferrer"><FaFacebookSquare className='v-middle social'/></a><a href="https://www.instagram.com/raitectecnologia/" target="_blank" rel="noopener noreferrer"><GrInstagram className='v-middle social'/></a><a href="https://www.linkedin.com/company/raitec/?originalSubdomain=br" target="_blank" rel="noopener noreferrer"><FaLinkedin className='v-middle social'/></a></span>
            </div>
          </div>
          <div className="contact-form-page">
            <h2>Vamos começar?</h2>
            <p className='description-form'>Sua empresa mais eficiente e atualizada.</p>
            <form onSubmit={handleSubmit}>
              <div className='form-space'>
                <label className='name-form wrong'>{errors.name}</label>
                <input id="name" type="text" value={formData.name} onChange={handleInputChange} placeholder="Nome Completo*" disabled={isSubmitting}  />
      
              </div>
              <div className='form-space'>
                <label className='email-form wrong'>{errors.email}</label>
                <input id="email" type="text" value={formData.email} onChange={handleInputChange} placeholder="Email*" disabled={isSubmitting}/>

              </div>
              <div className='form-space'>
                <label className='telefone-form wrong'>{errors.phoneNumber}</label>
                <input id="phoneNumber" type="tel" value={formData.phoneNumber} onChange={handleInputChange} maxlength="15" placeholder="Telefone*" disabled={isSubmitting}  />
              </div>
              <div className='form-space'>
                <input id="nameCompany" type="text" value={formData.nameCompany} onChange={handleInputChange} placeholder="Nome da Empresa" disabled={isSubmitting}  />
              </div>
              <div className='form-space'>
                <input id="cargo" type="text" value={formData.cargo} onChange={handleInputChange} placeholder="Cargo" disabled={isSubmitting} />
              </div>
              <div className='form-space'>
                <label className='msg-form wrong'>{errors.msg}</label>
                <textarea id="msg" value={formData.msg} onChange={handleInputChange} placeholder="Mensagem*" disabled={isSubmitting} ></textarea>
 
              </div>
              <div id='botao-form'>
                <div id='div-botao'>
                  <button  id='enviar-form' type="submit">ENVIAR <span><img className='gif' src={Carregando} alt="" /></span></button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className={isVisible? 'popup' : 'nao-aparece'} id="resultPopup">
          <div className="popup-content">
            <div className="popup-header">
              <img src={LogoEnvio} alt="logo-raitec" width="80px" height="50px"/>
            </div>
            <div className="popup-body">
              <p>Agradecemos pelo seu interesse em nossos serviços e soluções! Em breve, entraremos em contato.</p>
            </div>
            <div className="popup-footer">
              <button id="closePopup" onClick={handleClose}>Fechar</button>
            </div>
          </div>
        </div>


        <Footer />
      </main>
    </div>
  );
};

export default Contato;
